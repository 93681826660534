import { WindowLocation } from '@reach/router';

export const getStepTitle = (location?: WindowLocation) => {
  if (!location) {
    return;
  }

  const { pathname } = location;

  const isRepairDetails = pathname.includes('repair-details');
  const isHomeRecover = pathname.includes('home-recover');
  const isEvCharger = pathname.includes('charger-cover');
  const isEpdCover = pathname.includes('electrics-plumbing-drainage');
  const totalEvChargerSteps = 2;
  const totalStandAloneSteps = 3;
  const totalHomeRecoverSteps = 5;
  const totalSteps = isHomeRecover ? totalHomeRecoverSteps : totalStandAloneSteps;

  if (isRepairDetails) {
    return `Step 1 of ${totalHomeRecoverSteps}`;
  }

  if (pathname.includes('details')) {
    if (isEvCharger || isEpdCover) {
      return `Step 1 of ${totalEvChargerSteps}`;
    }
    return `Step ${isHomeRecover ? '2' : '1'} of ${totalSteps}`;
  }

  if (pathname.includes('boiler')) {
    return `Step ${isHomeRecover ? '3' : '2'} of ${totalSteps}`;
  }

  if (pathname.includes('payment')) {
    if (isEvCharger || isEpdCover) {
      return `Step 2 of ${totalEvChargerSteps}`;
    }

    if (isHomeRecover) {
      return `Step 4 of ${totalHomeRecoverSteps}`;
    }

    return `Step 3 of ${totalStandAloneSteps}`;
  }

  if (pathname.includes('summary')) {
    return `Step ${totalHomeRecoverSteps} of ${totalSteps}`;
  }
};
