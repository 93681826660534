import { Card, Col, Margin, Stack } from '@ovotech/element';
import styled from 'styled-components';
import { useAtomValue } from 'jotai';
import { useDecision } from '@optimizely/react-sdk';

import * as Logger from '@src/logging/logger';
import { BlackHeading4 } from '@src/Styles/Common.Styles';
import { useAuth } from '@hooks/useAuth';
import { useAppContext } from '@contexts/AppContext';
import { MixpanelEvents, Page } from '@entities/enums';
import { useCustomMixpanel } from '@services/Mixpanel';
import { PrimaryCTA } from '@components/PrimaryCTA';
import { journeyAtom } from '@src/store/store';
import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { Journey } from '@src/types/Journey';

const CenterTextCard = styled(Card)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.core.space[4]};
  padding-bottom: ${({ theme }) => theme.core.space[0]};
`;

const LoginCard = () => {
  const { user, loading, error } = useAuth();
  const journey = useAtomValue(journeyAtom);
  const { navigateToLogin, navigateToLoginv2 } = useAppContext();
  const [{ enabled: newLoginRedirectEnabled }] = useDecision(
    OptimizelyFeatureFlag.NEW_LOGIN_REDIRECT
  );
  const { track } = useCustomMixpanel();

  const redirectToLogin = () => {
    sessionStorage.setItem('lastElement', 'login-cta');
    track(MixpanelEvents.BUTTON_CLICK, {
      buttonId: 'login',
    });
    if (newLoginRedirectEnabled) {
      Logger.log('redirecting to login v2');
      navigateToLoginv2();
    } else {
      Logger.log('redirecting to login v1');
      navigateToLogin(journey === Journey.HomeRecover ? Page.REPAIR_DETAILS : Page.DETAILS);
    }
  };
  if ((loading || user) && !error) return null;
  return (
    <CenterTextCard>
      <Col small={12} medium={8}>
        <BlackHeading4>Already an OVO Customer?</BlackHeading4>
        <Margin top={6} />
        <Stack spaceBetween={6}>
          <PrimaryCTA iconRight={'chevron-right'} fullWidth={true} onClick={redirectToLogin}>
            Log in
          </PrimaryCTA>
        </Stack>
      </Col>
    </CenterTextCard>
  );
};

export default LoginCard;
